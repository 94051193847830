<template>
  <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%">
    <div class="main-class">
      <!--条件查询-->
      <el-form :model="queryParams" ref="queryForm" v-show="showSearch" :inline="true" label-width="68px">
        <el-form-item label="分类名称" prop="ip">
          <el-input
              v-model="queryParams.ip"
              placeholder="请输分类名称"
              clearable
              auto-complete="off"
              size="small"
              style="width: 200px;"
              @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-button
          type="success"
          plain
          icon="el-icon-s-promotion"
          size="mini"
          @click="synchronous"
          :loading="bt_loading"
          v-permission="['moban:tage:synchronous']"
      >同步分类
      </el-button>

      <el-button
          type="success"
          plain
          icon="el-icon-s-promotion"
          size="mini"
          @click="synchronousdetails"
          :loading="bt_detailsloading"
          v-permission="['moban:tage:synchronousdetails']"
      >详情信息同步
      </el-button>

      <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-permission="['sys:login:delete']"
      >删除
      </el-button>
      <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          @click="handleClean"
          v-permission="['sys:login:clear']"
      >清空
      </el-button>

      <el-table style="margin-top: 20px" border ref="tables" v-loading="loading" :data="list" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="编号" align="center" width="100" prop="id"/>
        <el-table-column label="名称" align="center" prop="title" :show-overflow-tooltip="true"/>
        <el-table-column label="创建时间" align="center" prop="creatTime"  width="200" :show-overflow-tooltip="true"/>
         <el-table-column prop="status" label="状态"   align="center"  width="150">
          <template slot-scope="scope">
            <el-tag size="small" v-if="scope.row.type === 0" type="warning">模版</el-tag>
            <el-tag size="small" v-else-if="scope.row.type === 1" type="success">关键词</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="数量"  width="100" align="center" prop="id" />
        <el-table-column
            label="操作"
            align="center"
            width="250"
            class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(JSON.stringify(scope.row))"
                v-permission="['sys:user:update']"
            >修改</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
                v-permission="['sys:user:delete']"
            >删除</el-button>
            <el-button
                type="text"
                icon="el-icon-refresh"
                size="mini"
                :loading="scope.row.bt_synchronousItem"
                @click="synchronousItem(scope.row)"
                v-permission="['moban:tage:synchronousdetails']"
            >同步
            </el-button>
          </template>
        </el-table-column>

      </el-table>

      <Pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  name: "Dict",
  data() {
    return {
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        ip: undefined,
        username: undefined,
        status: undefined,
      },
      tagesId : 0,
      // 默认排序
      defaultSort: {prop: 'loginTime', order: 'descending'},
      // 非多个禁用
      multiple: true,
      showSearch: true,
      // 选中数组
      ids: [],
      // 表格数据
      list: [],
      // 总条数
      total: 0,
      loading: false,
      bt_loading:false,
      bt_detailsloading:false,
      bt_synchronousItem:false,
      dateRange: []
    }
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios.get("/moban/list", {params: {
          ip: this.queryParams.id,
          username: this.queryParams.username,
          status: this.queryParams.status,
          start: this.dateRange[0],
          end: this.dateRange[1],
          pageNum: this.queryParams.pageNum,
          pageSize: this.queryParams.pageSize
        }}).then(res => {
        this.list = res.data.data.records
        this.total = res.data.data.total
        this.loading = false
      })
    },
    resetForm(formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm")
      this.handleQuery()
    },

    /** 同步分类按钮 */
    synchronous(){
      this.bt_loading = true
      this.$axios.get("/moban/synchronous").then(res => {
         console.log("同步分类按钮数据返回",res.data)
        if (res.data.code === 200) {
          this.modal.notifySuccess( res.data.msg+ ('成功'+res.data.data.success+'条,失败'+res.data.data.fail))
        }
        this.bt_loading = false
      })
    },

    synchronousdetails(){
      this.bt_loading = true
      this.tagesId = 0
      this.$axios.get("/moban/synchronousdetails?tagesId="+this.tagesId).then(res => {
        console.log("同步分类按钮数据返回",res.data)
        if (res.data.code === 200) {
          this.modal.notifySuccess( res.data.msg+ ('成功'+res.data.data))
        }
        this.bt_loading = false
      })
    },
    // item 同步
    synchronousItem(row){
      this.tagesId = row.id
      console.log("item 同步",this.tagesId)
      this.$set(row, 'bt_synchronousItem', true)
      this.$axios.get("/moban/synchronousdetails?tagesId="+this.tagesId).then(res => {
        console.log("同步分类按钮数据返回",res.data)
        if (res.data.code === 200) {
          this.$set(row, 'bt_synchronousItem', false)
          this.modal.notifySuccess( res.data.msg+ ('成功'+res.data.data))
        }
        this.bt_loading = false
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const infoIds = row.id || this.ids;
      this.modal.confirm('是否确认删除访问编号为"' + infoIds + '"的数据项？').then(function () {
      }).then(() => {
        this.$axios.delete("/sys/loginInfo?ids=" + infoIds).then(res => {
          if (res.data.code === 200) {
            this.modal.notifySuccess(res.data.data)
          }
          this.getList();
        })
      }).catch(() => {
      });
    },
    /** 清空按钮操作 */
    handleClean() {
      this.modal.confirm('是否确认清空所有登录日志数据项？').then(function () {
      }).then(() => {
        this.$axios.post("/sys/loginInfo").then(res => {
          if (res.data.code === 200) {
            this.modal.notifySuccess(res.data.data)
          }
          this.getList();
        })
      }).catch(() => {
      });
    },


    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
  },
  created() {
    this.getList()
  }
}
</script>

